import _ from 'lodash';

export default function (instance) {
  return {
    // getAll(config = {}) {
    //   return instance.get('partner/earn/courses', config);
    // },
    getAll(config = {}) {
      return instance.get('biz/partner/earn/courses/');
    },
  };
}
