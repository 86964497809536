import React from 'react'
import {
  Container,
  ButtonWrapper,
  TextWrapper,
  LeftWrapper,
  MainHeading,
  Subheading,
  LogoStyle,
  Header,
} from './styles'

import routes from 'utils/absoluteRoutes'
import { navigate } from '@reach/router'
import Button from 'components/button'


const NotFound = () => (
  <Container>
    <Header>
      <LogoStyle src={require('assets/images/zlto_logo_white.svg')} />
    </Header>
    <LeftWrapper>
      <TextWrapper>
        <MainHeading>Oops!</MainHeading>
        <Subheading>
          We can't seem to find the page you are looking for
        </Subheading>
      </TextWrapper>
      <ButtonWrapper>
        <Button variant='zltoLightBlue' onClick={() => navigate(routes.dashboard)}>
          Return to my admin dashboard
        </Button>
      </ButtonWrapper>
    </LeftWrapper>
  </Container>
)

export default NotFound
