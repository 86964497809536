export default function (instance) {
  return {
    get(config = {}) {
      return instance.post('client', config);
    },
    signup(body, config = {}) {
      return instance.post('client/signup/', body, config);
    },
  };
}
