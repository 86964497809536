export default function (instance) {
  return {
    getAll(config = {}) {
      return instance.get('store/stores', config);
    },
    purchase(itemId, config = {}) {
      return instance.post(`store/purchase/${itemId}`, config);
    },
    // getHistory(itemId, config = {}) {
    //   return instance.post('store/transaction/coupon/list', config);
    // },
    getItemPurchaseLog(itemId, config = {}) {
      return instance.get(`partner/store/product/purchased/${itemId}`, config);
    },
  };
}
