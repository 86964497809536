import { Container } from "unstated";
import { USER_DATA } from "constants/userConstants";
import LocalStorage from "utils/localStorage";
import CookieStorage from "js-cookie";

const DEFAULT_STATE = {
  displayName: '',
  email: '',
};

class User extends Container {
  constructor() {
    super();

    const { displayName, email, photoURL } =
      CookieStorage.getJSON(USER_DATA) || DEFAULT_STATE;

    this.state = {
      displayName,
      email,
      photoURL
    };
  }

  set = (data = {}) => {
    const updatedState = {
      ...this.state,
      ...data,
    };
    return this.setState(updatedState, () => {
      CookieStorage.set(USER_DATA, updatedState);
    });
  };

  clear() {
    return this.setState(DEFAULT_STATE, () => {
      CookieStorage.set(USER_DATA, DEFAULT_STATE);
    });
  }
}

export default new User();
