import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import App from "./root";
import { isLocalHost } from "utils/environment";

if (!isLocalHost) {
  Sentry.init({
    dsn: "https://57c02215852543939534393d87a40919@o879202.ingest.sentry.io/5834635",
    integrations: [
      new Integrations.BrowserTracing()
    ],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
