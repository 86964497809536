const auth = (instance) => {
  return {

    // TODO: change to zlto login
    login(body, config = {}) {

      // TODO: perform multiple steps for oauth
      return instance.post("auth/login", body, config);
    },

    register(body, config = {}) {
      return instance.post("auth/register", body, config);
    },

    registerCartedo(body, config = {}) {
      return instance.post("auth/register/cartedo", body, config);
    },

    session(body, config = {}) {
      return instance.post("auth/session", body, config);
    },

    resetPassword(body, config = {}) {
      return instance.post("auth/password-reset", body, config);
    },
  };
};

export default auth;
