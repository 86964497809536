import _ from 'lodash';

export default function (instance) {
  return {
    // getAll(config = {}) {
    //   return instance.get('partner/earn/surveys', config);
    // },
    getUsersJoined(questionnaire_id, limit, offset) {
      return instance.get(`partner/earn/surveys/completed/${questionnaire_id}?limit=${limit}&offset=${offset}`);
    },
    getUsersJoinedPage(url) {
      return instance.get(`${url}`);
    },
    getAll(config = {}) {
      return instance.get('biz/partner/earn/surveys/');
    },
  };
}
