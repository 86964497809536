import _ from 'lodash';

export default function (firebase, instance) {
  return {
    get(body, config = {}) {
      return firebase.database().ref('business_partners')
    },
    createAdmin(biz_partner_id, program_partner_id, user_id, email, type, payload, config = {}) {
      return firebase
      .database()
      .ref(`business_partners/${biz_partner_id}/program_partners/${program_partner_id}/admins/${user_id}`)
      .set({ email });
    },
  };
}
