// https://github.com/tiaanduplessis/stoor/blob/master/src/index.js

import inMemory from "./inMemory";
import isSupported from "./isLocalStorageSupported";

const ErrorsDict = {
    Invalid_Fallback: "Invalid fallback provided",
    Invalid_Key : "Failed key provided",
    Cannot_Set_Undefined : "Can not set value to undefined"
}

class LocalStorage {
  constructor({ namespace = "", fallback = inMemory, storage = "local" } = {}) {
    if (!(this instanceof LocalStorage)) {
      return new LocalStorage({ namespace, fallback, storage });
    }

    if (!fallback.getItem || !fallback.setItem || !fallback.removeItem) {
      throw new Error(ErrorsDict.Invalid_Fallback);
    }

    if (typeof window === "undefined") {
      this.storage = fallback;
    }
    
    if (storage === "session") {
      this.storage = isSupported(window.sessionStorage)
        ? window.sessionStorage
        : fallback;
    } else {
      this.storage = isSupported(window.localStorage)
        ? window.localStorage
        : fallback;
    }

    this.namespace = namespace;
  }

  get(key = "", def = null) {
    if (typeof key !== "string" || !key.length) {
      throw new Error(ErrorsDict.Invalid_Key);
    }

    if (Array.isArray(key)) {
      return key.map(currentKey => {
        const namespacedKey = `${this.namespace}:${currentKey}`;
        return JSON.parse(this.storage.getItem(namespacedKey));
      });
    }

    const namespacedKey = `${this.namespace}:${key}`;

    try {
      const result = JSON.parse(this.storage.getItem(namespacedKey));
      return result !== null ? result : def;
    } catch (error) {
      return def;
    }
  }

  set(key, value) {
    if (typeof key !== "string" || !key.length) {
      throw new Error(ErrorsDict.Invalid_Key);
    }

    if (key && value === undefined) {
      throw new Error(ErrorsDict.Cannot_Set_Undefined);
    }

    if (Array.isArray(key)) {
      return key.forEach(pair => {
        const [key, value] = pair;
        const namespacedKey = `${this.namespace}:${key}`;
        this.storage.setItem(namespacedKey, JSON.stringify(value));
      });
    } else {
      const namespacedKey = `${this.namespace}:${key}`;
      this.storage.setItem(namespacedKey, JSON.stringify(value));
    }

    return this;
  }

  remove(key) {
    if (Array.isArray(key)) {
      return key.map(currentKey => {
        const namespacedKey = `${this.namespace}:${currentKey}`;
        return this.storage.removeItem(namespacedKey);
      });
    } else {
      const namespacedKey = `${this.namespace}:${key}`;
      this.storage.removeItem(namespacedKey);
    }

    return this;
  }

  clear() {
    return this.storage.clear();
  }
}

export default LocalStorage;
