export default function (instance) {
  return {
    get(partner_id, config = {}) {
      return instance.database().ref().child(`business_partners/${496}`);
    },
    setRequirement(user_uuid, type, payload, config = {}) {
      return instance.database().ref(user_uuid)
      .child(`business_requirements/${type}`)
      .set(payload);
    },
  };
}
