import { fonts } from "assets/fontStyles";
import colours from "assets/colours";

const WORDS_PER_SECOND = 0.000002;
const ONE_SECOND = 1000;

function css(elem, rules = {}) {
  Object.keys(rules).forEach((key) => {
    elem.style[key] = rules[key];
  });
}

// https://stackoverflow.com/a/17980070/7027045
function stripHTML(html = "") {
  var tmp = document.implementation.createHTMLDocument("New").body;
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function getDurationBasedOnWords(content) {
  const words = stripHTML(content).split(" ");
  return (words.length * ONE_SECOND) / WORDS_PER_SECOND;
}

/**
 *
 * @param {string} title
 * @param {string} description
 * @param {"success" | "error" | "info"} type
 * @param {{}} opts
 */
function notification(
  title = "",
  description = "",
  theme = "",
  opts = {},
  hasIcon = true
) {
  const {
    className = "",
    duration = getDurationBasedOnWords(description),
    target = "body",
    offset = 15,
    styles = {},
    selector = "just-toasty",
    role = "alert",
    type = "polite",
    cb = () => {},
  } =
    typeof opts === "number"
      ? { duration: opts }
      : typeof opts === "function"
      ? { cb: opts }
      : opts;

  const [vert, hor] = ["top", "right"];
  const targetElem = document.querySelector(target);
  const elem = document.createElement("div");

  elem.setAttribute("role", role);
  elem.setAttribute("aria-live", type);
  elem.setAttribute("aria-atomic", "true");

  elem.classList.add(selector);
  className && elem.classList.add(className);

  const row = document.createElement("div");
  css(
    row,
    Object.assign({
      display: "flex",
    })
  );

  const col = document.createElement("div");
  css(
    col,
    Object.assign({
      display: "flex",
      flexDirection: "column",
      marginLeft: "30px",
    })
  );

  if (hasIcon) {
    const icon = document.createElement("img");
    icon.setAttribute(
      "src",
      require(`assets/images/${theme}-notification-icon.svg`)
    );
    css(
      row,
      Object.assign({
        display: "flex",
      })
    );
    row.appendChild(icon);
  }

  const heading = document.createElement("div");
  css(heading, Object.assign(fonts.heading6));
  heading.innerHTML = title;

  const body = document.createElement("div");
  body.innerHTML = description;
  css(
    body,
    Object.assign({
      fontSize: "16px",
      color: colours.grey2,
      marginTop: "10px",
      fontWeight: 500,
    })
  );

  col.appendChild(heading);
  col.appendChild(body);
  row.appendChild(col);
  elem.appendChild(row);

  css(
    elem,
    Object.assign(
      {
        display: "flex",
        alignItems: "center",
        [hor]: "15px",
        opacity: 1,
        zIndex: 9999,
        background: "#ffffff",
        position: "fixed",
        borderRadius: "6px",
        top: "-100px",
        fontFamily: "inherit",
        fontSize: "16px",
        fontWeight: "bold",
        transition: "all 0.4s ease-out",
        width: "375px",
        boxShadow: "1px 17px 30px rgba(194, 195, 199, 0.35)",
        padding: "25px 30px",
      },
      styles
    )
  );

  targetElem.insertBefore(elem, targetElem.firstChild);

  let topOffset = offset;

  document.querySelectorAll(`.${selector}`).forEach((elem) => {
    const height = elem.clientHeight;
    css(elem, {
      [vert]: topOffset + "px",
    });
    topOffset += height + offset;
  });

  setTimeout(function () {
    const width = elem.offsetWidth;
    css(elem, {
      [hor]: "-" + width + "px",
      opacity: 0,
    });
    setTimeout(() => {
      elem.remove();
      cb();
    }, 1000);
  }, duration);

  return elem;
}

const SUCCESS_TITLE = "Success";
const ERROR_TITLE = "Error";
const INFO_TITLE = "Information";
const WARNING_TITLE = "Warning";

const NOTIFY_PERIOD = 3500;

export function errorNotification(message) {
  notification(ERROR_TITLE, message, "error", NOTIFY_PERIOD);
}
export function successNotification(message) {
  notification(SUCCESS_TITLE, message, "success", NOTIFY_PERIOD);
}
export function infoNotification(message, period = NOTIFY_PERIOD) {
  notification(INFO_TITLE, message, "info", period);
}
export function warningNotification(message, period = NOTIFY_PERIOD) {
  notification(WARNING_TITLE, message, "warning", period);
}

/**
 * Pops success notification that reads "Save successful"
 */
export function successfulSaveNotification() {
  notification(SUCCESS_TITLE, "Save successful", "success", NOTIFY_PERIOD);
}
