import _ from 'lodash';

export default function (instance) {
  return {
    // getAll(config = {}) {
    //   return instance.get('biz/partner/earn/microtasks', config);
    // },
    getAll(config = {}) {
      return instance.get('biz/partner/earn/microtasks/main', config);
    },
    getUsersJoined(opportunity_id, config={}) {
      return instance.get(`partner/earn/microtasks/users/${opportunity_id}`, config);
    },
    creditUser({ client_uuid, opportunity_id }, config={}) {
      return instance.post('partner/earn/microtasks/credit', { client_uuid, opportunity_id }, config);
    },
    async getActive(config = {}) {
      const response = await instance.get('earn/microtasks', config);
      const active = _.get(response, 'active_microtasks', []);
      //filter by is_credited: false
      return active;
    },
    check(opportunity_id, config = {}) {
      return instance.get(`earn/microtasks/check/${opportunity_id}`, config);
    },
    join(body, config = {}) {
      return instance.post('earn/microtasks/opt/', body, config);
    },
    submitRequirement(body, config = {}) {
      return instance.post('earn/microtasks/unshaped/submit/', body, config);
    },
    submitTask(body, config = {}) {
      return instance.post('earn/microtasks/unshaped/submit/', body, config);
    },
  };
}
