export default function (firebase) {
  return {
    createMentor(biz_partner_id, program_partner_id, payload, config = {}) {
      return firebase
        .database()
        .ref(`business_partners/${biz_partner_id}/program_partners/${program_partner_id}/mentors`)
        .push()
        .set(payload);
    },
    assignMentor(biz_partner_id, program_partner_id, mentee_id, mentor_id, mentor, config = {}) {
      return firebase
        .database()
        .ref(`business_partners/${biz_partner_id}/program_partners/${program_partner_id}/users/${mentee_id}/mentors/${mentor_id}`)
        .set(mentor);
    },
  };
};
